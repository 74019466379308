import $ from "jquery";

function stickyNavigation() {
    var scrollTop = $(window).scrollTop();

    if (scrollTop > 20) {
        $(".navbar-inner").addClass("sticky");
    } else {
        $(".navbar-inner").removeClass("sticky");
    }
}
function init(params) {
    $(window).on("scroll load", function () {
        stickyNavigation();
    });

    $(".toggle-menu .btn-toggle").on("click", function () {
        $(".header-menu").toggleClass("open");
        if ($(".header-menu").hasClass("open")) {
            let height = $(window).height() - $(".navbar-inner").outerHeight();
            $(".header-menu").height(height);
            $("#main-content").addClass("nav-open");
        } else {
            $("#main-content").removeClass("nav-open");
            $(".header-menu").height(0);
        }
    });
}

export default init();
