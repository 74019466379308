import $ from "jquery";

function init(params) {
    let path =window.location.pathname;
    if (path != "/featured-stories" && !/\/(tag)|(category)?\/[-\w+]*/.test(path)) return;
    
    let postsToShow = 12;
    $(window).on("load", function () {
        if (postsToShow >= $(".card").length) {
            $("#show-more-posts").addClass("d-none");
        }
        $($(".card").slice(postsToShow)).each(function (index, element) {
            $(this).parent().addClass("d-none");
        });
    });
    $("#show-more-posts").on("click", function () {
        postsToShow += 12;
        
        $(".card").each(function (index, element) {
            if (index < postsToShow) {
                $(this).parent().removeClass("d-none");
            } else {
                $(this).parent().addClass("d-none");
            }
        });

        if (postsToShow >= $(".card").length) {
            $("#show-more-posts").addClass("d-none");
        }
    });
}

export default init();
