import $ from "jquery";

function init(params) {
    let inputWidth = $(".header-menu").outerWidth() - 100;
    let input = $(".input-search");

    $(window).on("resize", function () {
        if (input.hasClass("active")) {
            let decrease = $(".header-menu").is(":visible") ? 100 : 300;
            let widthElement = $(".header-menu").is(":visible")
                ? $(".header-menu")
                : $(".navbar-inner");
            inputWidth = widthElement.outerWidth() - decrease;
            input.css({ width: inputWidth + "px" });
        }
    });

    $(".search-submit").on("click", function () {
        if ($(this).find(".fa-paper-plane").hasClass("active")) {
            $("#top-search-form").trigger("submit");
        } else if (input.hasClass("active")) {
            input.css({ width: 0 });
            input.removeClass("active");
            $(this).removeClass("active");
            $(this).find("i").removeClass("active");
            $(this).find(".fa-search").addClass("active");
        } else {
            input.addClass("active");
            $(this).addClass("active");

            input.css({ width: inputWidth + "px" });
            input.trigger("focus");

            $(this).find("i").removeClass("active");
            $(this).find(".fa-xmark").addClass("active");

            $(input).on("keyup", function (e) {
                if ($(this).val().length === 0) {
                    $(".search-submit").find("i").removeClass("active");
                    $(".search-submit").find(".fa-xmark").addClass("active");
                } else {
                    $(".search-submit").find("i").removeClass("active");
                    $(".search-submit")
                        .find(".fa-paper-plane")
                        .addClass("active");
                }
            });
        }
    });
}

export default init();
