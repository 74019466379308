import $ from "jquery";
import { CountUp } from "countup.js";
function init(params) {
    let countAnimations = [];
    $(".count-animation").each(function (index) {
        var id = $(this).attr("id");
        if (!id) {
            $(this).attr("id", "column" + index);
            id = $(this).attr("id");
        }
        var amount = $(this).html();
        countAnimations[index] = new CountUp(id, amount);
    });

    $(document).ready(function () {
        for (var i = 0; i < countAnimations.length; i++) {
            if (!countAnimations[i].error) {
                countAnimations[i].start();
            } else {
                console.error(countAnimations[i].error);
            }
        }
    });
}

export default init();
