import $  from "jquery";
function init(params) {
    $(".button-video").on("click", function () {
        let url = $(this).data("src");
        let id = $(this).data("bs-target");
    
        $(id + " iframe").attr("src", url);
    
        const modal = document.getElementById($(this).data("target"));
    
        modal.addEventListener("shown.bs.modal", (event) => {
            $(id + " iframe").attr("src", url + "&autoplay=1");
        });
        modal.addEventListener("hidden.bs.modal", (event) => {
            $(id + " iframe").removeAttr("src");
        });
        $(document).on("keydown", function (e) {
            if (e.originalEvent.key == "Escape") {
                $(".modal-header .btn-close").trigger("click");
                $(document).off("keydown");
            }
        });
    });
}

export default init()